<template>
  <div class="center">
        <el-input
          v-model="search"
          @focus="focus"
          @blur="blur"
          @keyup.enter.native="searchHandler"
          placeholder="搜索商品名称"
          prefix-icon="el-icon-search"
          @input="searchEvent"
          @keyup.up.native="_handleUp"
          @keyup.down.native="_handleDown"
        >
          <!-- <el-button slot="append" id="search" @click="searchHandler"
            >搜索</el-button
          > -->
          <template #suffix>
            <span style="line-height:36px; color:#3D3D3D; cursor: pointer;padding-right:15px;font-size:16px" @click="clearInput">取消</span>
          </template>
        </el-input>
        <!---设置z-index优先级,防止被走马灯效果遮挡-->
        <div class="hot_search" v-show='HotFlag'>
          <P>热门搜索</P>
          <span  v-for="search in HotWordsList" :key="search.id"  @click="HotWordsFun(search.words) " 
             :class="{'selectActive':searchVal== search.words,'is-active':searchVal !== search.words}"
            >{{search.words}}</span>
            <div class="clear"></div>
        </div>
        <!-- 搜索下拉框 start  -->
        <!-- @mouseenter="enterSearchBoxHanlder" -->
        <el-card
          v-if="isSearch"
          class="box-card"
          id="search-box"
          style="position:absolute;z-index:15"
        >
          <!-- <dl v-if="isHistorySearch">
            <dt class="search-title" v-show="history">历史搜索</dt>
            <dt
              class="remove-history"
              v-show="history"
              @click="removeAllHistory"
            >
              <i class="el-icon-delete" aria-hidden="true"   ></i>清空历史记录
            </dt>
            <el-tag
              v-for="search in historySearchList"
              :key="search.id"
              closable
              :type="search.type"
              @close="closeHandler(search)"
              style="margin-right:5px; margin-bottom:5px;"
              >{{ search.name }}</el-tag
            >
            <dt class="search-title">热门搜索</dt>
            <dd v-for="search in hotSearchList" :key="search.id">
              {{ search }}
            </dd>
          </dl> -->
          <dl id="menu-list">
            <dd v-for="(search,i) in searchList" :key="search.id"
            :class="{'is-active': i===activeIndex,'selectActive': i===selectIndex}"
            @click="seachValFun(search)"
            >
            {{ search }}</dd>
          </dl>
        </el-card>

         <!-- 搜索下拉框 end  -->
  </div>
</template>

<script>
import {
  SearchProduct,
} from "api/product.js";
import { HotWords } from "api/product.js";
export default {
  data() {
    return {
      search: "", //当前输入框的值
      isFocus: false, //是否聚焦
      // isSearch:false,
      searchList:[],
      selectIndex:0,
      activeIndex:0,
      HotWordsList:[],
      searchVal:'',
      HotFlag:true,
    };
  },
  props:{
       isSearch:{
         type:Boolean
       }
     },
  created() {
    var lett = this;
    document.onkeydown = function() {
        var key = window.event.keyCode;
        if (key == 13) {
         lett.searchHandler()
        }
    }
    const parmesData = {
                  //暂无
                  current: 1,
                  size:10
                };
          HotWords(parmesData).then(res=>{
            // console.log('sdghjkl;',res.data.data.records)
            this.HotWordsList=res.data.data.records
          })
  },
  methods: {
    clearInput() {
      this.search = ''; // 清空输入框的内容
      this.$emit('clearInpFun',false);
    },
   // 此时实时搜索，调取接口
    searchEvent(){
      this.HotFlag=true
      this.clearTimer();
      // if($route.path)
      if (this.search && this.search.length > 0) {
        //获取当前延时函数的ID，便于后面clearTimeout清除该ID对应的延迟函数
        this.timer = setTimeout(() => {
              const parmesData = {
                  //暂无
                  keyword: this.search,
                  current: 1,
                  size: 10
                };
              SearchProduct(parmesData).then((res) => {

                // //console.log(res.data.data)
                if(res.data.data.length>0){
                    // this.isSearch=true
                    this.HotFlag=false
                    this.$emit('searchFun',true);
                    this.searchList= res.data.data;

                }else{
                  //  this.isSearch=false
                   this.HotFlag=true
                    this.$emit('searchFun',false);
                   this.searchList=[]
                }
                // historySearchList
                // this.searchProducts = records;
                // this.total = Number(res.data.data.total);
              });
        }, 500);
      } else {
          // this.isSearch=false
          this.$emit('searchFun',false);
               this.searchList=[]
      }
    },

_handleUp(){
            //  实现对样式的替换
            this.selectIndex = this.selectIndex === 0 ? this.searchList.length - 1 : this.selectIndex - 1;

            let selectNum = this.selectIndex + 1;
            let search = document.getElementById("menu-list");
            let intervalTop = search.scrollTop;
this.search=this.searchList[selectNum-1].name;
            //  实现在用户操作是,滚轮随着上下移的动
            if (selectNum * 30 <= intervalTop){

                search.scrollTop = (selectNum - 1) * 30;

            } else if (selectNum * 30 <= intervalTop + search.offsetHeight){

                search.scrollTop = intervalTop;

            } else if (selectNum * 30 > intervalTop){

                search.scrollTop = selectNum * 30;
            }

        },
    _handleDown(){
            this.selectIndex = this.selectIndex < this.searchList.length - 1 ? this.selectIndex + 1 : 0;
            let selectNum = this.selectIndex + 1;
            let search = document.getElementById("menu-list");
            let intervalTop = search.scrollTop;
this.search=this.searchList[selectNum-1].name;
            if (selectNum * 30 <= intervalTop){

                search.scrollTop = (selectNum - 1) * 30;

            } else if (selectNum * 30 <= intervalTop + search.offsetHeight){

                search.scrollTop = intervalTop;

            } else if (selectNum * 30 > intervalTop){

                search.scrollTop = selectNum * 30 - search.offsetHeight;
            }

        },

    clearTimer() {
          if (this.timer) {
            clearTimeout(this.timer);
          }
    } ,
    focus() {
      this.isFocus = true;
      this.searchEvent()
    },
    blur() {
      var self = this;
      this.searchBoxTimeout = setTimeout(function() {
        self.isFocus = false;
      }, 300);
    },
    seachValFun(seachName){
          this.$emit('searchFun',false);
        this.searchList=[]
        this.search=seachName
        localStorage.setItem("searchVal", this.search);
        this.$router.push({name:"ProductType",query:{type:11,seachVal:this.search}})

        // this.search = ''; // 清空输入框的内容
        this.$emit('clearInpFun',false);
    },
    searchHandler() {
      if(this.$route.path=="/ordersumbit"){
       return false
      } 
      if(this.search){
        this.$emit('searchFun',false);
        this.searchList=[]
        localStorage.setItem("searchVal", this.search);
        this.$router.push({name:"ProductType",query:{type:11,seachVal:this.search}})
      }else{
        this.$message({
            message:'请输入搜索值',
            type:"warning"
        })
      }

    },


     //热词点击
     HotWordsFun(HotWords) {
       localStorage.setItem("searchVal", HotWords);
        this.$router.push({name:"ProductType",query:{type:11,seachVal:HotWords}})
          this.searchVal=localStorage.getItem("searchVal")
         this.HotFlag=false


         this.search = ''; // 清空输入框的内容
         this.$emit('clearInpFun',false);
    },

  },
  computed: {
  },
};
</script>

<style scoped lang="less">
@import '~style/index.less';

.center {
  // margin-top: 10px;
  // margin-left: 0px;
  width: 800px;
  margin: 0 auto;
  position: relative;
  /deep/ .el-input__inner{
        // border-color:#ff3333;
            height: 38px;
    line-height: 38px;
    // border-radius:0px;
    border-radius: 20px;
    background: #F0F0F0;
   

  }
  /deep/.el-input__icon{
    color: #3D3D3D;
    font-weight: bold;
  } 
 
}
#search {
  background-color: #ff3333;
  border-radius: 0%;
  color: #fff;
  font-size: 14px;
  float: right;
  
}
.search-title {
  color: #606266;
  font-size: 15px;
  margin-bottom: 5px;
}
.remove-history {
  color: #bdbaba;
  font-size: 15px;
  float: right;
  margin-top: -22px;
}
::-webkit-scrollbar  
{  
    width:3px;  
}
  
/*定义滚动条轨道 内阴影+圆角*/  
::-webkit-scrollbar-track  
{  
    border-radius: 10px;  
    background-color: rgba(0,0,0,0.1); 
} 
  
/*定义滑块 内阴影+圆角*/  
::-webkit-scrollbar-thumb  
{  
    border-radius: 10px;  
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);  
    background-color: rgba(0,0,0,0.1);
} 
#search-box {
  width:800px;
  // height:auto;
  margin-top: 0px;
  padding-bottom:10px;
  font-size: 13px;
  height: 300px;
  overflow-y: scroll;
  dl{
    dd{
      line-height: 30px;
      font-size:15px;
      color: #666;
      &:hover{
        cursor: pointer;
        font-weight: bold;
        color: #303133;
      }
      &.selectActive{
        font-weight: bold;
        color: #303133;
      }
    }
  }

}
.el-input__inner {
  border: 1px solid #ff3333;
  font-size: 12px;
}
.hot_search {
  margin-top:36px;
    padding:0 60px 30px 60px;
    text-align: left;
    background: #fff;
    width: 800px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    left: -60px;
    p{
      line-height: 50px;
      font-weight: 18px;
      font-weight: 400;
      color: #3D3D3D;
    }
    .clear{
      clear: both;
    }
    span {
      display: block;
      float: left;
      width: 125px;
      height: 46px;
      white-space:nowrap;
      text-overflow:ellipsis;
      overflow:hidden;
      background: #F0F0F0;
      color: #3D3D3D;
      font-size:16px;
      font-weight:300;
      text-align: center;
      line-height: 46px;
      margin:10px 17px;
      &:hover{
        cursor: pointer;
        font-weight: bold;
        color: #ff3333;
      }
       &.selectActive{
        font-weight: bold;
        color: #ff3333;
      }
    }
  }
</style>
